import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { FaPlay } from "react-icons/fa";
import ReactLoading from "react-loading";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const generateUniqueId = () => {
  return `rider_${new Date().getTime()}_${Math.random()
    .toString(36)
    .substr(2, 9)}`;
};

const AdPage = () => {
  const location = useLocation();
  const [paymentNumber, setPaymentNumber] = useState("");
  const [isAdWatched, setIsAdWatched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [playNow, setPlayNow] = useState(false);
  const [adsArray, setAdsArray] = useState([]); // To store the ads array
  const [noAdsAvailable, setNoAdsAvailable] = useState(false); // For handling "No ads available"
  const [isPaying, setIsPaying] = useState(false);

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    let riderId = localStorage.getItem("rideAd247_rider_identity");
    if (!riderId) {
      riderId = generateUniqueId();
      localStorage.setItem("rideAd247_rider_identity", riderId);
    }

    const queryParams = new URLSearchParams(location.search);
    const driverId = queryParams.get("driverId");

    const filterAds = async () => {
      try {
        const response = await axios.post(
          "https://apiv2.ridead247.com/ads/filter",
          {
            rider_id: riderId,
            driver_id: driverId
          }
        );
        console.log(response, "response");
        if (response.data.message === "No ads available") {
          setNoAdsAvailable(true);
          console.log("no ads");
        } else {
          setAdsArray(response.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error filtering ads:", error);
        setNoAdsAvailable(true);
        setIsLoading(false);
      }
    };

    // Call the function to filter ads
    filterAds();
  }, [location]);

  const handleAdEnd = () => {
    setIsAdWatched(true);
    setPlayNow(false); // Set playNow to false after ad ends to show replay button
  };

  const handleClaim = () => {
    const queryParams = getQueryParams(window.location.search);
    const driverId = queryParams.get("driverId");

    if (!paymentNumber) {
      toast.error("Please enter a payment number.", {
        hideProgressBar: true
      });
      return;
    }

    setIsPaying(true);

    fetch("https://apiv2.ridead247.com/claim-payment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        driverId,
        riderPhone: paymentNumber
      })
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Payments processed:", data);
        setIsPaying(true);
        toast.success("Payment processed successfully!", {
          hideProgressBar: false
        });
      })
      .catch((error) => {
        console.error("Error processing payment:", error);
        setIsPaying(false);
        toast.error("Error processing payment. Please try again.", {
          hideProgressBar: false
        });
      });
  };

  return (
    <div className="flex flex-col items-center bg-black h-screen w-screen justify-center overflow-hidden">
      {isLoading ? (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-blue-500">
          <ReactLoading
            type={"bars"}
            color={"white"}
            height={200}
            width={200}
          />
          <p className="text-white">Please Wait ...</p>
        </div>
      ) : noAdsAvailable ? ( // Show "No ads available" screen
        <div className="flex flex-col items-center justify-center text-white">
          <h2 className="text-2xl">No ads available at the moment.</h2>
          <p>Please check back later for more ads.</p>
        </div>
      ) : (
        <>
          <div className="w-full max-w-3xl h-full relative">
            <ReactPlayer
              url={adsArray.ad_url}
              controls={false}
              playing={playNow}
              onEnded={handleAdEnd}
              width="100%"
              height="100%"
              style={{
                maxHeight: "100%",
                margin: "0 auto",
                objectFit: "cover"
              }}
              onReady={(player) => {
                const videoElement = player.getInternalPlayer();
                if (videoElement) {
                  videoElement.setAttribute("playsinline", "true");
                  videoElement.removeAttribute("controls");
                }
              }}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    disablePictureInPicture: true,
                    playsInline: true
                  }
                }
              }}
            />

            <div className="absolute top-0 m-5 flex items-end justify-center">
              <button
                onClick={() => window.open(`${adsArray.ad_url}, "_blank"`)}
                className="p-4 mb-10 rounded-lg bg-blue-600 text-white hover:bg-blue-400 transition-all"
              >
                Visit Our Website
              </button>
            </div>

            {!playNow && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <button
                  onClick={() => setPlayNow(true)}
                  className="p-4 rounded-full bg-blue-700 hover:bg-blue-300 transition-all flex justify-center items-center duration-300"
                >
                  <FaPlay className="text-white text-l" />
                </button>
              </div>
            )}
          </div>

          {isAdWatched && (
            <div className="absolute bottom-5 w-full flex flex-col items-center justify-center space-y-4">
              <h2 className="text-lg text-white">Claim Your Payment</h2>

              <div
                className={`flex ${
                  isPaying ? "justify-center" : "gap-3"
                } w-full px-4 max-w-lg`}
              >
                {!isPaying && (
                  <input
                    type="text"
                    placeholder="Enter your payment number"
                    value={paymentNumber}
                    onChange={(e) => setPaymentNumber(e.target.value)}
                    className="p-4 border-2 border-blue-600 bg-transparent text-white w-full rounded-xl text-lg outline-none"
                  />
                )}

                <div
                  onClick={!isPaying ? handleClaim : undefined}
                  className={`flex p-[15px] justify-center items-center rounded-2xl w-full 
                 ${
                   isPaying
                     ? "bg-blue-400 cursor-not-allowed"
                     : "bg-blue-600 cursor-pointer"
                 }`}
                  disabled={isPaying}
                >
                  <h2 className="text-lg font-bold text-white">
                    {isPaying ? "Processing..." : "Claim"}
                  </h2>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdPage;

