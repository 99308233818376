import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../redux/userActions";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { successToast, errorToast } from "./CustomToast";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = async () => {
    if (username && password) {
      try {
        const loginResponse = await fetch(
          "https://apiv2.ridead247.com/drivers/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              phone: username,
              password: password
            })
          }
        );

        if (!loginResponse.ok) {
          // Parse the error message
          const errorData = await loginResponse.json(); // Parse response as JSON
          const errorMessage = errorData.error || loginResponse.statusText; // Extract the error message
          throw new Error(` ${errorMessage}`);
        }

        const data = await loginResponse.json();
        dispatch(login(data));
        successToast("Login successful!");
      } catch (error) {
        console.error("Error during login:", error);
        errorToast(`${error.message || "An error occurred during login"}`);
      }
    } else {
      errorToast("Please enter valid credentials!");
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen mx-6">
      <div className="mb-16 flex flex-col items-center gap-5">
        <h1 className="text-4xl font-bold text-blue-700">Login here</h1>
        <p className="font-bold">Login to your driver Dashboard</p>
      </div>
      <div className="flex gap-5 flex-col justify-center items-center w-full">
        <div className="w-full flex flex-col max-w-[400px]">
          <label className="pl-2">Phone number</label>
          <input
            type="text"
            placeholder="Enter phone number"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="p-4 bg-gray-100 text-l mt-2 rounded-xl w-full max-w-[400px] outline-none"
          />
        </div>
        <div className="w-full flex flex-col max-w-[400px] relative">
          <label className="pl-2">Password</label>
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="p-4 bg-gray-100 text-l mt-2 rounded-xl w-full max-w-[400px] outline-none"
          />
          <div
            className="absolute top-12 right-6 cursor-pointer"
            onClick={toggleShowPassword}
          >
            {showPassword ? (
              <EyeSlashIcon className="w-6 h-6 text-gray-600" />
            ) : (
              <EyeIcon className="w-6 h-6 text-gray-600" />
            )}
          </div>
        </div>

        <button
          onClick={handleLogin}
          className="p-4 font-bold text-l rounded-xl w-full bg-blue-600 text-white mt-6 max-w-[400px]"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;

