import React from "react";
import { useSelector } from "react-redux";
import { QRCodeCanvas } from "qrcode.react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing
import { CogIcon } from "@heroicons/react/24/solid"; // Settings icon from Heroicons

const Dashboard = () => {
  const user = useSelector((state) => state.user);

  console.log(user);
  const navigate = useNavigate(); // Hook to navigate between routes

  const adLink = `https://ridead247.com/play-ad?driverId=${user.driver._id}`; // Local link for testing

  const goToEditProfile = () => {
    navigate("/edit-profile"); // Navigate to the EditProfile page
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="absolute top-4 right-4">
        <button
          onClick={goToEditProfile}
          className="p-2 rounded-full bg-gray-300 hover:bg-gray-400"
        >
          <CogIcon className="h-6 w-6 text-gray-700" />
        </button>
      </div>
      <div className="p-4 bg-white shadow-lg rounded-lg">
        <QRCodeCanvas value={adLink} size={300} />
      </div>

      <h1 className="text-l mt-4 font-bold">
        {user.driver.first_name} {user.driver.last_name} - {user.driver.phone}
      </h1>
    </div>
  );
};

export default Dashboard;

