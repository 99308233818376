import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastConfig = {
  hideProgressBar: true,
  closeButton: true,
  autoClose: 3000
};

export const successToast = (message) => {
  toast.success(message, toastConfig);
};

export const errorToast = (message) => {
  toast.error(message, toastConfig);
};

