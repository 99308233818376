import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import AdPage from "./components/AdPage";
import EditProfile from "./components/EditProfile";

function App() {
  const user = useSelector((state) => state.user);

  return (
    <Router>
      <div className="App">
        <Routes>
          {user ? (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/edit-profile" element={<EditProfile />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/play-ad" element={<AdPage />} />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;

