// src/store/reducers/userReducer.js
const initialState = {
  user: null
};

// Action Types
const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const UPDATE_DETAILS = "UPDATE_DETAILS";

// Reducer
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, user: action.payload };
    case LOGOUT:
      return { ...state, user: null };
    case UPDATE_DETAILS:
      return { ...state, user: { ...state.user, ...action.payload } };
    default:
      return state;
  }
};

export default userReducer;
